<template>
  <div :key="deviceId">
    <div class="detail">
      <a-row style="margin-top: 0" :gutter="[24, 0]">
        <a-col class="detail_title" :sm="24">
          <span class="detail_title_v">设备详情</span>
          <span class="detail_title_btn">
            <a-button @click="navBack" type="primary">返回</a-button>
            <a-button type="primary" icon="reload" @click="update" />
            <!-- <a-button type="primary" @click="test">test</a-button> -->
          </span>
        </a-col>
      </a-row>
      <a-row style="margin-top: 0" :gutter="[24, 24]">
        <a-col :xs="15">
          <div class="detail_zoneLeft">
            <a-divider orientation="left">
              <a-icon type="book" style="color:#069EFF;" /><span>设备信息</span>
            </a-divider>
            <a-spin :spinning="spinning">
              <a-row style="margin: 0 20px" :gutter="[2, 2]">
                <a-col :sm="5">
                  <div class="detail_zoneLeft_i">
                    <a-carousel class="detail_zoneLeft_i_c" autoplay>
                      <img
                        class="detail_zoneLeft_img"
                        v-if="deviceimg.length === 0"
                        :src="defaultImage"
                      />
                      <img
                        class="detail_zoneLeft_img"
                        v-for="(item, index) in deviceimg"
                        :key="index"
                        :src="item.path"
                        @click="handlePreview(item.path)"
                      />
                    </a-carousel>
                  </div>
                  <a-modal
                    :visible="previewVisible"
                    :footer="null"
                    @cancel="handleCancel"
                  >
                    <img
                      alt="example"
                      style="width: 100%"
                      :src="deviceimg_prev"
                    />
                  </a-modal>
                </a-col>
                <a-col :sm="{ span: 17, offset: 1 }">
                  <a-row style="margin-top: 0" :gutter="[24, 24]">
                    <a-col
                      class="detail_zoneLeft_info"
                      :sm="{ span: 11 }"
                      style="padding: 0 12px"
                    >
                      <info-item title="设备ID：">{{
                        device.deviceSn
                      }}</info-item>
                      <info-item title="设备型号：">{{
                        deviceModel.deviceModelType
                      }}</info-item>
                      <info-item
                        title="设备名称："
                        :value="deviceModel.deviceModelName"
                      />
                      <info-item title="设备地址：" :value="device.address" />
                    </a-col>
                    <a-col
                      class="detail_zoneLeft_info"
                      :sm="{ span: 11, offset: 1 }"
                      style="padding: 0 12px"
                    >
                      <info-item title="出厂日期：">{{
                        device.dateOut
                      }}</info-item>
                      <info-item title="机器状态：">
                        <span v-if="deviceStatus === null" style="color:brown"
                          >离线</span
                        >
                        <span
                          v-else-if="deviceStatus === '0'"
                          style="color:green"
                        >
                          运行</span
                        >
                        <span
                          v-else-if="deviceStatus === '1'"
                          style="color:#00b5ff"
                          >开机</span
                        >
                        <span v-else style="color:red">故障</span>
                      </info-item>
                      <info-item title="故障信息："
                        ><span>{{ deviceErrs }}</span
                        ><a style="color:#FFA200" @click="showFaultList">
                          (故障记录)</a
                        ></info-item
                      >
                      <info-item title="是否授权：">
                        <span
                          v-if="device.permissionStatus"
                          style="color:#26cb59"
                          ><a-icon type="safety-certificate" theme="filled" />
                          已授权</span
                        >
                        <span v-else style="color:#fa5e3d"
                          ><a-icon type="minus-circle" theme="filled" />
                          未授权</span
                        >
                      </info-item>
                    </a-col>
                  </a-row>
                  <div class="detail_zoneLeft_total">
                    <ul>
                      <li v-if="deviceModel.yjgf === 1">
                        <span>{{ device.processInformation || '有机固废' }}总处理量：</span
                        ><span>{{ ttlData.ttlIn + "kg" }}</span>
                      </li>
                      <li v-if="deviceModel.yllj === 1">
                        <span>园林垃圾总处理量：</span
                        ><span>{{ ttlData.ttlPltWst + "kg" }}</span>
                      </li>
                      <li>
                        <span>总产出物：</span
                        ><span>{{ ttlData.ttlOut + "kg" }}</span>
                      </li>
                      <li>
                        <span>总减碳量：</span
                        ><span>{{ ttlData.ttlGt + "kg" }}</span>
                      </li>
                      <span />
                    </ul>
                  </div>
                </a-col>
                <popup-form
                  class="newuser"
                  :visible="faultListVisible"
                  :width="1200"
                  :maskClosable="false"
                  title="故障记录"
                  :footer="null"
                  @cancel="hideFaultList"
                >
                  <fault-list ref="reffl" :id="deviceId" :sn="deviceSn" />
                </popup-form>
              </a-row>
            </a-spin>
            <a-divider orientation="left">
              <a-icon type="book" style="color:#069EFF;" /><span>监控信息</span>
              <!-- <span @click="changeRealTime">测试</span> -->
            </a-divider>
            <div class="detail_zoneLeft_monitor"> 
              <monitor-table 
                ref="refmt"
                :id="deviceId"
                :sn="deviceSn"
              />
            </div>
            <a-divider orientation="left">
              <a-icon type="book" style="color:#069EFF;" /><span>客户信息</span>
            </a-divider>
            <a-spin :spinning="spinning">
              <a-row style="margin: 0 20px" :gutter="[24, 6]">
                <a-col :sm="6">
                  <info-item title="安装时间：">{{
                    device.dateInstall
                  }}</info-item>
                </a-col>
                <a-col :sm="6">
                  <info-item
                    title="单位名称："
                    :value="customer.customerName"
                  />
                </a-col>
                <a-col :sm="6">
                  <info-item title="联系人：">{{
                    liaison.liaisonName
                  }}</info-item>
                </a-col>
                <a-col :sm="6">
                  <info-item title="联系电话：">{{ liaison.phone }}</info-item>
                </a-col>
                <a-col :sm="6">
                  <info-item title="产量规模："
                    >{{ customer.produceScale }} KG/天</info-item
                  >
                </a-col>
                <a-col :sm="6">
                  <info-item
                    title="单位地址："
                    :value="customer.customerFullAddress"
                  />
                </a-col>
                <a-col :sm="6">
                  <info-item title="验收时间：">{{
                    device.dateAccept
                  }}</info-item>
                </a-col>
                <a-col :sm="6">
                  <info-item title="质保到期：">{{
                    device.dateQuality
                  }}</info-item>
                </a-col>
              </a-row>
            </a-spin>
            <a-divider orientation="left">
              <a-icon type="book" style="color:#069EFF;" /><span>设备数据</span>
              <!-- <span v-if="showRealTime" @click="changeRealTime">实时参数</span>
              <span v-else @click="changeRealTime">历史数据</span> -->
            </a-divider>
            <div class="detail_zoneLeft_chart">
              <div class="detail_zoneLeft_chart_switch">
                <span
                  :class="showRealTime ? 'checked' : ''"
                  @click="changeRealTime(true)"
                >
                  实时参数
                </span>
                <span
                  :class="{'checked' : !showRealTime}"
                  @click="changeRealTime(false)"
                >
                  历史数据
                </span>
                <span v-if="!showRealTime" @click="chartSelect">
                  功能：
                  <a-tooltip placement="top">
                    <template slot="title">
                      <span
                        >框选历史图表中的某个时间段，可以获取该时间段内更详细的数据</span
                      >
                    </template>
                    <span :class="chartSelected ? 'checked' : ''">{{
                      chartSelected ? "选区放大" : "选区放大"
                    }}</span>
                  </a-tooltip>
                  <span v-if="rangeSelected" @click="zoomSelect">放大</span>
                  <span v-if="chartZoomed" @click="resetZoom">还原</span>
                </span>
              </div>
              <temp-chart
                ref="reftc"
                class="chart"
                :class="{'hidden' : !showRealTime}"
                :id="deviceId"
                :height="240"
                :sn="deviceSn"
              />
              <temp-chart2
                class="chart"
                :class="{'hidden' : showRealTime}"
                ref="reftc2"
                :height="240"
                :sn="deviceSn"
                @rangeSelect="rangeSelect"
              />
            </div>
          </div>
        </a-col>
        <a-col :xs="9">
          <div class="detail_zoneRight">
            <a-divider orientation="left">
              <a-icon type="book" style="color:#069EFF;" /><span>运行信息</span>
            </a-divider>
            <div class="detail_zoneRight_detail">
              <opt-chart
                title="日处理量"
                ref="refoc"
                :height="310"
                :id="deviceId"
                :sn="deviceSn"
              />
              <div class="detail_zoneRight_detail_if2">
                <div class="detail_zoneRight_detail_if2_card">
                  <div>
                    <a-card class="left_info_card" :bordered="false">
                      <a-statistic
                        title="总处理量(kg)"
                        :value="dayData.in"
                        class="left_info_card_v"
                        :value-style="{ color: '#28ce59' }"
                      />
                    </a-card>
                  </div>
                  <div>
                    <a-card class="left_info_card" :bordered="false">
                      <a-statistic
                        title="总产出物(kg)"
                        :value="dayData.out"
                        class="left_info_card_v"
                        :value-style="{ color: '#136FFB' }"
                      />
                    </a-card>
                  </div>
                  <div>
                    <a-card class="left_info_card" :bordered="false">
                      <a-statistic
                        title="总减碳量(kg)"
                        :value="dayData.gt"
                        class="left_info_card_v"
                        :value-style="{ color: '#8C73FF' }"
                      />
                    </a-card>
                  </div>
                  <div>
                    <a-card class="left_info_card" :bordered="false">
                      <a-statistic
                        title="运行时间"
                        :value="runtimes"
                        class="left_info_card_v"
                        :value-style="{ color: '#FEA236' }"
                      />
                    </a-card>
                  </div>
                  <div>
                    <a-card class="left_info_card" :bordered="false">
                      <a-statistic
                        title="总耗电量(度)"
                        :value="dayData.kwh"
                        class="left_info_card_v"
                        :value-style="{ color: '#FF6363' }"
                      />
                    </a-card>
                  </div>
                </div>
              </div>
              <log-table
                ref="reflt"
                :key="key + 3"
                :id="deviceId"
                :clw="device.processInformation"
                :yjgf="deviceModel.yjgf === 1"
                :yllj="deviceModel.yllj === 1"
                :sn="deviceSn"
              />
            </div>
          </div>
        </a-col>
      </a-row>
    </div>
    <!-- <a-card :bordered="false">
      <detail-list>
        <a-button type="primary" style="margin-left: 8px" @click="navBack"
          >后退</a-button
        >
      </detail-list>
    </a-card> -->
  </div>
</template>

<script>
// import DetailList from "@/components/tool/DetailList";
// import PageLayout from '@/layouts/PageLayout'
// import * as echarts from 'echarts';
// const DetailListItem = DetailList.Item;
import OptChart from "./OptChart";
import TempChart from "../../components/TempChart";
import TempChart2 from "./TempChart";
import LogTable from "./LogTable";
import MonitorTable from "./MonitorTable";
import InfoItem from "../../components/InfoItem.vue";
import DefaultImage from "@/assets/img/lyb_sample1.jpg";
import FaultList from "./FaultList.vue";
import PopupForm from "@/pages/components/PopupForm";
import { dataSource as ds } from "@/services";
import { GETMONFILE } from "@/services/api";
import websocket_plug from "@/plugins/websocket";
// import DefaultImage from "@/assets/img/preview.png.jpg"
import { format } from "date-fns";
import { mapState } from "vuex";

export default {
  name: "BasicDetail",
  // eslint-disable-next-line vue/no-unused-components
  components: {
    // DetailListItem,
    // DetailList,
    OptChart,
    TempChart,
    LogTable,
    InfoItem,
    FaultList,
    PopupForm,
    TempChart2,
    MonitorTable,
  },
  mixins: [websocket_plug],
  data() {
    return {
      defaultImage: DefaultImage,
      GETMONFILE: GETMONFILE,
      previewVisible: false,
      faultListVisible: false,
      deviceId: 0,
      deviceSn: 0,
      deviceimg: [],
      deviceimg_prev: "",
      key: 1,
      dayData: {
        dn: 0,
        kgin: 0,
        gt: 0,
        kgout: 0,
        runtime: 0,
      },
      ttlData: {
        ttlIn: 0,
        ttlOut: 0,
        ttlPltWst: 0,
        ttlGt: 0,
      },
      sstime: format(new Date("2000-01-01"), "yyyy-MM-dd HH:mm:ss"),
      stime: format(
        new Date(new Date().getTime() - 1000 * 30 * 60 * 60 * 24),
        "yyyy-MM-dd HH:mm:ss"
      ),
      etime: format(new Date(new Date().getTime()), "yyyy-MM-dd HH:mm:ss"),
      device: {
        deviceId: "",
        deviceSn: "",
        address: "",
        longitude: "",
        latitude: "",
        dateOut: "",
        dateInstall: "",
        dateAccept: "",
        dateQuality: "",
        permissionStatus: "",
        deviceInfos: "",
        deviceErrs: "",
        processInformation: "",
      },
      liaison: {
        liaisonId: "",
        liaisonName: "",
        phone: "",
        master: 0,
        createtime: "",
        customerId: "",
      },
      customer: {
        customerId: "",
        customerName: "",
        province: null,
        city: null,
        town: null,
        customerAddress: "",
        customerFullAddress: "",
        produceScale: 0,
        uid: "",
        remarks: "",
      },
      deviceModel: {
        deviceModelId: "",
        deviceModelName: "",
        deviceModelType: "",
        remarks: "",
        yjgf: 0,
        yllj: 0,
      },
      realTimeChartReady: false,
      showRealTime: true,
      visiable: false,
      spinning: false,
      timeChanged: false,
      chartSelected: false,
      rangeSelected: false,
      selectedRange: null,
      chartZoomed: false,
    };
  },
  computed: {
    ...mapState("date", ["timeRange"]),
    runtimes() {
      return `${Math.floor(this.dayData.runtime / 60)}时${Math.round(this.dayData.runtime %
        60)}分`;
    },
    deviceStatus() {
      if (this.device.deviceInfos) {
        return this.device.deviceInfos.devSta;
      }
      return null;
    },
    deviceErrs() {
      if (this.device.deviceErrs) {
        return this.device.deviceErrs.errContent;
      }
      return "无";
    },
  },
  watch: {
    timeRange: function(li) {
      // console.log("timeRange改变", li);
      this.stime = li[0];
      this.etime = li[1];
      if (this.visiable) this.updateAllbyDate();
      else this.timeChanged = true;
    },
  },
  mounted() {
    // this.drawChart();
    this.key = Date.now();
    this.stime = this.timeRange[0];
    this.etime = this.timeRange[1];
    console.log("device_detail mounted");
    // this.ws_connect();
  },
  activated() {
    console.log("device_detail activated");
    console.log("params", this.$route);
    this.deviceId = this.$route.query.id;
    this.deviceSn = this.$route.query.sn;
    this.visiable = true;
    this.spinning = true;
    this.update();
    this.setup_url(
      this.deviceSn,
      Date.now() * 100 + Math.floor(Math.random() * 100)
    );
    this.ws_connect(() => {
      this.ws_bindMsg(this.getWsData);
    });
  },
  deactivated() {
    console.log("device_detail deactivated");
    this.visiable = false;
    this.showRealTime = true;
    this.ws_close();
  },
  beforeRouteEnter(to, form, next) {
    console.log("beforeRouteEnter");
    next();
  },
  beforeDestroy() {
    console.log("device_detail beforeDestroy");
    this.ws_close();
  },
  methods: {
    updateAllbyDate() {
      this.dayData = {
        dn: 0,
        kgin: 0,
        gt: 0,
        kgout: 0,
        runtime: 0,
      };
      // this.ttlData = {
      //   ttlIn: 0,
      //   ttlOUt: 0,
      //   ttlGt: 0,
      // };
      this.updateDayData();
      this.$refs["reftc2"].update(this.deviceSn, this.stime, this.etime, false);
      this.chartSelected = false;
      this.rangeSelected = false;
      this.selectedRange = null;
      this.chartZoomed = false;
      this.$refs["refoc"].update(this.deviceSn, this.stime, this.etime);
      this.$refs["reflt"].update(this.deviceSn, this.stime, this.etime);
      this.getTotal();
    },
    update() {
      this.key = Date.now();
      ds.getDevice(this.deviceId)
        .then((res) => {
          console.log("getDevice", res);
          if (res.data.meta.success) {
            Object.keys(this.device).map((str) => {
              this.device[str] = res.data.data.device[str];
            });
            if (res.data.data.device.liaison !== null) {
              Object.keys(this.liaison).map((str) => {
                this.liaison[str] = res.data.data.device.liaison[str];
              });
            }
            if (res.data.data.device.customer !== null) {
              Object.keys(this.customer).map((str) => {
                this.customer[str] = res.data.data.device.customer[str];
              });
              this.handleFullAddress(this.customer);
            }
            if (res.data.data.device.deviceModel !== null) {
              Object.keys(this.deviceModel).map((str) => {
                this.deviceModel[str] = res.data.data.device.deviceModel[str];
              });
            }
            if (res.data.data.device.imgs.length != 0) {
              // let url = res.data.data.device.imgs[0].path;
              // console.log("deviceImg", res.data.data.device.imgs, url);
              this.deviceimg = res.data.data.device.imgs;
            } else {
              this.deviceimg = "";
            }
            this.deviceSn = this.device.deviceSn;
            console.log(
              "device",
              this.device,
              this.liaison,
              this.customer,
              this.deviceModel
            );
            this.updateDayData();
            this.$refs["reftc"].update(this.deviceSn, this.stime);
            this.updateAllbyDate();
            // this.$refs["reftc2"].update(
            //   this.deviceSn,
            //   this.stime,
            //   this.etime,
            //   false
            // );
            // this.$refs["refoc"].update(this.deviceSn, this.stime, this.etime);
            // this.$refs["reflt"].update(this.deviceSn, this.stime, this.etime);
            this.getBindVideo();
          } else {
            this.$message.error(res.data.meta.msg);
          }
        })
        .catch((err) => {
          console.log("getDeviceErr", err);
        })
        .finally(() => {
          this.spinning = false;
        });
    },
    getWsData(e) {
      console.log("getWsData", e.data);
      this.$refs["reftc"].pushdata(e.data);
    },
    updatettlData(v) {
      this.ttlData = {
        ttlIn: v.ttlIn ?? 0,
        ttlOut: v.ttlOut ?? 0,
        ttlGt: v.gutan ?? 0,
        ttlPltWst: v.ttlPltWst ?? 0,
        // ttlGt: v.ttlIn ? Math.floor(v.ttlIn * 147) / 100 : 0,
      };
    },
    updateDayData() {
      ds.deviceDayData({
        deviceSn: this.deviceSn,
        etime: this.etime,
        stime: this.stime,
      })
        .then((res) => {
          console.log("deviceDayData", res);
          if (res.data.meta.success) {
            let st = res.data.data.data;
            this.dayData = st;
            this.dayData.in = Number(((st?.in || 0) + (st?.pltWst || 0)).toFixed(2));
            // this.dayData.out = Number(((st?.out || 0) + (st?.pltWst || 0)).toFixed(2));  // 加上园林垃圾
            this.dayData.out = Math.floor(this.dayData.out * 100) / 100; //帮处理小数点
            this.dayData.gt = Math.floor(this.dayData.in * 147) / 100;
          }
        })
        .catch((err) => {
          console.log("deviceDayDataErr", err);
        });
    },
    navBack() {
      this.$router.go(-1);
    },
    showFaultList() {
      this.faultListVisible = true;
      this.$nextTick(() => {
        this.$refs["reffl"].update();
      });
    },
    hideFaultList() {
      this.faultListVisible = false;
    },
    handleCancel() {
      this.previewVisible = false;
    },
    handlePreview(path) {
      this.deviceimg_prev = path;
      this.previewVisible = true;
    },
    handleFullAddress(obj) {
      let la = [obj.province,obj.city,obj.town]
      this.loadAddressData(la).then(res => {
        obj.customerFullAddress = res + ' ' + obj.customerAddress;
      })
    },
    async loadAddressData(value) {
      console.log("loadAddressData", value);
      let localString = "";
      for (let i = 0; i < value.length; i++) {
        let p = i === 0 ? 0 : value[i - 1];
        try {
          let res = await ds.regionList(p);
          console.log("loadAddressData regionList", res);
          if (res.data.meta.success) {
            if (Array.isArray(res.data.data.data)) {
              let l = res.data.data.data;
              let fd = l.find((v) => v.id === value[i]);
              if (fd) {
                localString += fd.regionName + '';
              }
            }
          }
        } catch (e){
          console.error("loadAddressData region Error", e);
        }
      }
      console.log("localString", localString);
      return localString;
    },
    drawChart() {
      //     // 基于准备好的dom，初始化echarts实例
    },
    test() {
      // this.key = Date.now();
      // this.$refs["reftc"].pushdata();
      ds.deviceStatusData().then((res) => {
        console.log("deviceStatusData", res);
      });
    },
    chartSelect() {
      console.log("chartSelect");
      this.chartSelected = !this.chartSelected;
      this.$refs["reftc2"]?.dispatchBrush(this.chartSelected);
    },
    rangeSelect(v) {
      console.log("rangeSelect", v);
      if (v === null) {
        this.rangeSelected = false;
      } else {
        this.rangeSelected = true;
        this.selectedRange = v;
      }
    },
    zoomSelect() {
      console.log("zoomSelect", this.selectedRange);
      let stime = this.selectedRange[0].replace("_", " ");
      let etime = this.selectedRange[1].replace("_", " ");
      this.$refs["reftc2"].update(this.deviceSn, stime, etime, false);
      this.chartZoomed = true;
    },
    resetZoom() {
      this.$refs["reftc2"].update(this.deviceSn, this.stime, this.etime, false);
      this.chartZoomed = false;
    },
    lastestData(v) {
      console.log("lastestData", v);
      this.updatettlData(v);
    },
    changeRealTime(v) {
      console.log("changeRealTime");
      this.showRealTime = v;
    },
    async getBindVideo() {
      this.$refs['refmt'].update(this.deviceId);
    },
    async getTotal() {
      try {
        let res = await ds.getTotal(this.deviceId);
        console.log('getTotal', res);
        if (res.data.meta.success) {
          let data = res.data.data.data;
          this.lastestData(data);
        }
      } catch (err) {
        console.err('getTotal err', err);
      }
    }
  },
};
</script>

<style lang="less" scoped>
// .title {
//   color: @title-color;
//   font-size: 16px;
//   font-weight: 500;
//   margin-bottom: 16px;
// }
.detail {
  background: rgba(0, 20, 74, 0.8);
  border: 1px solid #027cc3;
  box-shadow: 0px 0px 25px 0px rgba(0, 138, 255, 0.4);
  height: 920px;
  margin-top: 8px;
  &_title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-top: 20px;
    &_v {
      font-size: 24px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #00ecff;
    }
    &_btn {
      position: absolute;
      right: 45px;
      width: 200px;
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
      button {
        margin-left: 10px;
      }
    }
  }
  &_zoneLeft {
    color: #fff;
    &_i {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 160px;
      margin-left: 20px;
      &_c {
        width: 100%;
        height: 100%;
        :deep(.slick-slide) {
          text-align: center;
          height: 160px;
          line-height: 160px;
          overflow: hidden;
        }
      }
    }
    &_total {
      border-top: 1px solid #0051ac;
      margin: 10px 10px 0;
      padding: 10px 0 0 0;
      > ul {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-inline-start: 20px;
        margin-bottom: 0px;
        > li {
          &::marker {
            color: #00ecff;
          }
          > span:nth-child(0) {
            &::before {
              content: " ";
            }
          }
          span:nth-child(2) {
            color: #00ecff;
          }
        }
      }
    }
    &_chart {
      position: relative;
      margin: 0 2px 0 24px;
      padding: 20px;
      height: 280px;
      background: rgba(4, 16, 46, 0);
      border: 1px solid #1453a4;
      overflow: hidden;
      &_switch {
        position: absolute;
        top: 10px;
        left: 150px;
        // border: 1px solid #1453a4;
        z-index: 100;
        cursor: pointer;
        > span {
          border: 1px solid #1453a4;
          display: inline-block;
          padding: 3px 20px;
          user-select: none;
          &.checked {
            background: #177ddc;
            pointer-events: none;
          }
        }
        > span:nth-child(3) {
          margin-left: 32px;
          padding: 3px 5px;
          border: 1px dashed #1453a4;
          > span {
            border: 1px solid #1453a4;
            padding: 2px 13px;
            border-radius: 5px;
            margin-right: 12px;
            &.checked {
              background: #177ddc;
              pointer-events: all;
            }
          }
          > span:last-child {
            margin-right: 0;
          }
        }
      }
      .chart {
        position: relative;
        &.hidden {
          position: absolute;
          height: 100%;
          width: calc(100% - 40px);
          top: 500px;
        }
        &:not(.hidden) {
          position: absolute;
          height: 100%;
          width: calc(100% - 40px);
        }
      }
    }
    &_monitor {
      margin: 0 30px;
    }
  }

  &_zoneRight {
    margin-right: 24px;
    &_detail {
      padding: 20px;
      background: rgba(2, 33, 91, 0);
      border: 1px solid #1453a4;
      &_if2 {
        margin-top: 18px;
        margin-bottom: 20px;
        &_card {
          display: flex;
          justify-content: space-evenly;
          div {
            flex: 1;
          }
        }
      }
    }
    .left_info_card {
      background: #02215b;
      display: flex;
      align-items: center;
      justify-content: center;
      :deep(.ant-card-body) {
        padding: 15px 2px;
        // display: flex;
        // align-items: center;
        // justify-content: center;
      }
    }
  }

  :deep(.ant-divider) {
    margin: 5px 0;
    .ant-divider-inner-text {
      span {
        color: #0184ff;
        margin-left: 5px;
      }
      ::after {
        border-color: #0084ff;
      }
    }
  }

  :deep(.ant-divider-inner-text) {
    color: #fff;
  }
  :deep(.ant-divider-horizontal.ant-divider-with-text-left::after) {
    top: 0;
    border-top: 1px dashed #fff5;
  }
  :deep(.ant-divider-horizontal.ant-divider-with-text-left::before) {
    top: 0;
    width: 1%;
    border-top-style: none;
  }
  :deep(.ant-statistic-title) {
    color: #00ECFF;
  }
}

.left_info_card {
  margin: 2px;
}

.detail_zoneLeft_img {
  // max-height: 180px;
  // max-width: 180px;
}

.detail_zoneLeft_info > div {
  margin: 0 12px 12px;
}
</style>
<style lang="less"></style>
